<template>
  <header class="wow animated slideInUp" />
  <section class="xuan-zhe wow animated slideInUp">
    <p class="wei-s-m">为什么选择立享花</p>
    <p class="ce-lue">“五大策略”提升业绩</p>
    <div class="box">
      <div v-for="(item,index) in testArrTop" :key="index" class="test-item">
        <div class="test-box-img">
          <img :src="item.img">
        </div>
        <p class="test-box-title">{{ item.title }}</p>
        <p class="test-box-content" v-html="item.content" />
      </div>
    </div>
    <div class="box width79">
      <div v-for="(item,index) in testArrBottom" :key="index" class="test-item">
        <div class="test-box-img">
          <img :src="item.img">
        </div>
        <p class="test-box-title">{{ item.title }}</p>
        <p class="test-box-content" v-html="item.content" />
      </div>
    </div>
  </section>
  <div class="huan-jie wow animated slideInUp">
    <p class="huan-jie-title">“三大环节”赋能品牌</p>
    <div class="huan-jie-content">
      <img src="./images/img_sandahuanjie.png" class="huan-jie-content-left">
      <div class="huan-jie-content-right">
        <p class="black-border">售前——获客增流</p>
        <p>0首付0息费，营销新卖点，爆款占市场</p>
        <p class="black-border mt100">售中——精准转换</p>
        <p>结合产品定制支付方案，降低消费门槛，</p>
        <p>提升转化率和客单价</p>
        <p class="black-border mt100">售后——策略升级</p>
        <p>消费数据分析、产品营销升级、</p>
        <p>销售策略提升、营销工具开发。</p>
      </div>
    </div>
  </div>
  <l-business-number class="l-business-number wow animated slideInUp" title="为顾客提供更轻松的购物体验、<br/>更聪明的支付方式" />
  <l-business class="wow animated slideInUp" />
  <section class="success wow animated slideInUp">
    <p class="success-title">成功案例</p>
    <div class="success-box">
      <div v-for="(item,index) in successArr" :key="index" class="success-item">
        <div class="success-box-img">
          <img :src="item.img">
        </div>
        <p class="success-box-title">{{ item.title }}</p>
        <p class="success-box-content" v-html="item.content" />
      </div>
    </div>
  </section>
  <section class="he-zuo wow animated slideInUp">
    <p class="he-zuo-title">生态合作</p>
    <img src="./images/img_shengtai.png" class="he-zuo-img">
  </section>
  <section class="jia-ru wow animated slideInUp">
    <p class="jia-ru-title">立马加入立享花</p>
    <div class="add-box">
      <div v-for="(item,index) in addArr" :key="index" class="add-item">
        <div class="add-item-img">
          <img :src="item.img">
        </div>
        <p class="add-item-title">{{ item.title }}</p>
        <p class="add-item-content" v-html="item.content" />
      </div>
      <div class="line-one" />
      <div class="line-two" />
    </div>
    <a class="jia-ru-btn" href="#foot">
      打造品牌价值 开启更多增长<span class="pl20">></span>
    </a>
  </section>
</template>

<script setup>
import LBusiness from './component/l-business.vue'
import lBusinessNumber from './component/l-business-number.vue'
import { WOW } from 'wowjs'
import { onMounted } from 'vue'
const businessArr = [
  { img: require('./images/img_a.png'), title: '家居家具', logo: require('./images/icon_jiaju.png') },
  { img: require('./images/img_b.png'), title: '口腔健康', logo: require('./images/icon_jiaju.png') },
  { img: require('./images/img_c.png'), title: '植发护理', logo: require('./images/icon_jiaju.png') },
  { img: require('./images/img_d.png'), title: '产后修复', logo: require('./images/icon_jiaju.png') },
  { img: require('./images/img_e.png'), title: '婚庆婚礼', logo: require('./images/icon_jiaju.png') },
  { img: '', title: '更多行业<br/>敬请期待' }
]// 商家图
const testArrTop = [
  { img: require('./images/icon_lianhe.png'), title: '联合营销，增强获客能力', content: '找准卖点，聚焦营销，拓宽推广渠道，<br/>线上线下联动助力流量和客群增长。' },
  { img: require('./images/icon_shuozhu.png'), title: '锁住客户，提高转化', content: '让无单变有单：解决销售环节中“支<br/>付犹豫”的核心问题，降低消费门槛，<br/>避免意向客户流失，大幅度提升销售<br/>转化率。' },
  { img: require('./images/icon_jiangdi.png'), title: '降低价格敏感，提高成交单价 ', content: '让小单变大单：降低消费者对价格的敏<br/>感度，释放高价位产品的销售空间，放<br/>大消费者的购买力，实现商户销售业绩<br/>长效稳步增长。' }
]// 选择立享花图
const testArrBottom = [
  { img: require('./images/icon_shuzi.png'), title: '数字运营，营销升级', content: '大额费用分阶段支付，减少一次性付款<br/>压力，让无单变有单；每期支付费用大<br/>大降低，削弱消费者对价格敏感度，让<br/>小单变大单。' },
  { img: require('./images/icon_tisheng.png'), title: '提升口碑，激活复购', content: '立享花帮助商户为消费者提供一种聪明<br/>的消费方式，传递时尚健康消费理念，<br/>解除消费疑虑，从而提升消费者对商户<br/>的信任度和依赖度。' }
]// 选择立享花图
const successArr = [
  { img: require('./images/img_zhifa.png'), title: '成都·某植发品牌', content: '与立享花合作后，整体销售额同比提升<br/>38%，其中约35%的交易订单来自立享花<br/>线上线下联动助力流量和客群增长。' },
  { img: require('./images/img_jiaju.png'), title: '重庆·某产康品牌', content: '通过抢先为用户提供立享花分期支付服务，<br/>在享受优质产康服务的同时，提供低购买<br/>门槛，满足客户真实购物需求，订单量明<br/>显提升20%' },
  { img: require('./images/img_kouqiang.png'), title: '重庆·某口腔品牌 ', content: '联合立享花，向用户提供灵活支付计划，<br/>平均客单价提高25%。优质的口腔服务<br/>加上轻松的支付方式，整体提升客户转<br/>介绍机率，复购率提高20%' }
]// 成功图
const addArr = [
  { img: require('./images/icon_shangwu.png'), title: 'Step1:商务洽谈', content: '商户入驻咨询，确定合作意向' },
  { img: require('./images/icon_qianyue.png'), title: 'Step2：签约入驻', content: '提交申请资料，签署合作协议' },
  { img: require('./images/icon_tuiguang.png'), title: 'Step3：联合推广 ', content: '商户接入立享花服务，提供培训<br/>和营销支持，开启生意新模式' }
]// 加入立享花图
onMounted(() => {
  new WOW().init()
})
</script>

<style lang='less' scoped>
a{
  text-decoration:none;
}
p{
  margin:10px 0;
}
header{
  width:100%;
  height:700px;
  background:url('./images/bg_03.png');
  background-size:100% 100%;
  margin-top:-90px;
}
.xuan-zhe{
  background: #FFFFFF;
  padding:60px 0 0 0;
  text-align:center;
  .wei-s-m{
    font-size: 50px;
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #000000;
  }
  .ce-lue{
    font-size: 40px;
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 400;
    color: #000000;
  }
  .width79{
    width:791px !important;
  }
  .box{
    margin:86px auto 0;
    width:1156px;
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
    .test-item{
      // width: 290px;
      .test-box-img{
        width: 290px;
        height: 200px;
        background: #FFFFFF;
        box-shadow: 0px 10px 30px 0px rgba(199, 209, 221, 0.5);
        display:flex;
        align-items:center;
        justify-content:center;
        >img{
          width:140px;
          height:130px;
        }
      }
      .test-box-title{
        margin-top:41px;
        font-size: 22px;
        font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
        font-weight: 500;
        color: #191919;
      }
      .test-box-content{
        font-size: 18px;
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        font-weight: 400;
        color: #666666;
        line-height: 25px;
        margin-top:15px;
        text-align:left;
      }
    }
  }
}
.huan-jie{
  width: 100%;
  background: #edf2fd;
  padding: 130px 0 225px;
  margin-top:112px;
  .huan-jie-title{
    font-size: 50px;
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #000000;
    text-align:center;
  }
  .mt100{
    margin-top:100px;
  }
  .black-border{
    width:251px;
    height:47px;
    display: flex;
    align-items:center;
    justify-content:center;
    background:url("./images/img_hei.png");
    background-size:100%;
    font-size: 26px;
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #FFFFFF;
  }
  .huan-jie-content{
    width:993px;
    margin:140px auto 0;
    display: flex;
    justify-content:space-between;
    align-items:center;
  }
  .huan-jie-content-left{
    width:526px;
    height:445px;
  }
  .huan-jie-content-right{
    width:436px;
    font-size: 20px;
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 400;
    color: #191919;
  }
}
.success{
  margin:161px 0 121px;
  font-size: 16px;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  color: #666666;
  .success-title{
    text-align:center;
    font-size: 50px;
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #191919;
  }
  .success-box{
    display: flex;
    justify-content:space-between;
    width:1200px;
    margin:61px auto 0;
      .success-item{
      // width:290px;
      .success-box-img{
        width:290px;
        height:170px
      }
      .success-box-title{
        font-size: 20px;
        font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
        font-weight: 500;
        color: #191919;
      }
      .success-box-content{
        line-height:26px;
      }
    }
  }
}
.he-zuo{
  margin-top:82px;
  background: #ECF2FE;
  padding:130px 0 98px;
  .he-zuo-title{
    text-align: center;
    font-size: 50px;
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #000000;
    line-height: 69px;
  }
  .he-zuo-img{
    display: block;
    margin:59px auto 0;
    width: 467px;
    height: 444px;
  }
}
.jia-ru{
  width: 100%;
  background: #4481FD;
  padding:130px 0 110px;
  font-size: 16px;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
  .jia-ru-title{
    text-align: center;
    font-size: 50px;
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 69px;
  }
  .add-box{
    width:1028px;
    margin:97px auto 0;
    display:flex;
    justify-content: space-between;
    position: relative;
    .line-one,.line-two{
      width: 94px;
      height: 1px;
      background: #FFFFFF;
      position: absolute;
      bottom:88px;
    }
    .line-one{
      left:250px;
    }
    .line-two{
      left:660px;
    }
    .add-item{
      display:flex;
      flex-direction: column;
      align-items: center;
      .add-item-img{
        width:124px;
        height:124px;
      }
      .add-item-title{
        margin:27px 0 0;
        font-size: 30px;
        font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 42px;
      }
      .add-item-content{
        margin-top:8px;
      }
    }
  }
  .jia-ru-btn{
    font-size: 26px;
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #FFFFFF;
    margin:74px auto 0;
    width: 450px;
    height: 75px;
    background: #34CC98;
    border-radius: 39px;
    display: flex;
    align-items: center;
    justify-content:center;
    cursor: pointer;
  }
  .pl20{
    padding-left:20px;
  }
}
</style>
